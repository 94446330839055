// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchRegistrations = async () => {
      const { data, error } = await supabase
        .from('registrations')
        .select('*');
      if (error) {
        toast({
          title: 'Error fetching registrations',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        setRegistrations(data);
      }
    };
    fetchRegistrations();
  }, [toast]);

  const handleLogout = () => {
    logout();
    navigate('/sign-in');
  };

  return (
    <Box p={8}>
      <Button onClick={handleLogout} colorScheme="teal" mb={4}>
        Logout
      </Button>
      <Heading mb={6}>Registration Responses</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name of Patient</Th>
            <Th>Age</Th>
            <Th>Birthday</Th>
            <Th>Name of Parent/Guardian</Th>
            <Th>Cellphone Number</Th>
            <Th>Services</Th>
            <Th>Consulted</Th>
            <Th>Name of Pediatrician</Th>
            <Th>Diagnosis</Th>
            <Th>Promo Package</Th>
            <Th>PWD Cardholder</Th>
          </Tr>
        </Thead>
        <Tbody>
          {registrations.map((registration) => (
            <Tr key={registration.id}>
              <Td>{registration.name_of_patient || 'N/A'}</Td>
              <Td>{registration.age || 'N/A'}</Td>
              <Td>{registration.birthday || 'N/A'}</Td>
              <Td>{registration.name_of_parent_guardian || 'N/A'}</Td>
              <Td>{registration.cellphone_number || 'N/A'}</Td>
              <Td>{registration.services ? registration.services.join(', ') : 'N/A'}</Td>
              <Td>{registration.consulted || 'N/A'}</Td>
              <Td>{registration.name_of_pediatrician || 'N/A'}</Td>
              <Td>{registration.diagnosis || 'N/A'}</Td>
              <Td>{registration.promo_package ? registration.promo_package.join(', ') : 'N/A'}</Td>
              <Td>{registration.pwd_cardholder || 'N/A'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Dashboard;
