import React, { useState } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Select, Checkbox, CheckboxGroup, Stack, Button, Radio, RadioGroup, Textarea, useToast, VStack, HStack, FormHelperText } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';

const RegistrationForm = () => {
  const [nameOfPatient, setNameOfPatient] = useState('');
  const [age, setAge] = useState('');
  const [birthday, setBirthday] = useState('');
  const [city, setCity] = useState('');
  const [nameOfParent, setNameOfParent] = useState('');
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  const [services, setServices] = useState([]);
  const [consulted, setConsulted] = useState('no');
  const [nameOfPediatrician, setNameOfPediatrician] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [promoPackage, setPromoPackage] = useState([]);
  const [pwdCardholder, setPwdCardholder] = useState('no');
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (services.length === 0) {
      toast({
        title: 'An error occurred.',
        description: 'Please select at least one service to avail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { data, error } = await supabase
      .from('registrations')
      .insert([
        {
          name_of_patient: nameOfPatient,
          age,
          birthday,
          city,
          name_of_parent_guardian: nameOfParent,
          cellphone_number: cellphoneNumber,
          services,
          consulted,
          name_of_pediatrician: consulted === 'yes' ? nameOfPediatrician : null,
          diagnosis: consulted === 'yes' ? diagnosis : null,
          promo_package: promoPackage,
          pwd_cardholder: pwdCardholder
        }
      ]);

    if (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Successfully added to the waitlist.',
        description: 'You will be contacted by Tiny Triumphs.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    }
  };

  return (
    <Box p={8} maxWidth="800px" mx="auto">
      <Heading mb={6}>Join the Waitlist</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="flex-start">
          <FormControl isRequired>
            <FormLabel>Name of Patient</FormLabel>
            <Input
              placeholder="Name of Patient"
              value={nameOfPatient}
              onChange={(e) => setNameOfPatient(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Age</FormLabel>
            <Select
              placeholder="Select age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            >
              <option value="< 12 months">{'< 12 months'}</option>
              <option value="1 year">1 year</option>
              <option value="2 years">2 years</option>
              <option value="3 years">3 years</option>
              <option value="4 years">4 years</option>
              <option value="5 years">5 years</option>
              <option value="6 years">6 years</option>
              <option value="7 years">7 years</option>
              <option value="8 years">8 years</option>
              <option value="9 years">9 years</option>
              <option value="10 years">10 years</option>
              <option value="11 years">11 years</option>
              <option value="12 years">12 years</option>
              <option value="13 years">13 years</option>
              <option value="14 years">14 years</option>
              <option value="15 years">15 years</option>
              <option value="16 years">16 years</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Birthday</FormLabel>
            <Input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>City of Residence</FormLabel>
            <Input
              placeholder="City of Residence"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Name of Parent/Guardian</FormLabel>
            <Input
              placeholder="Name of Parent/Guardian"
              value={nameOfParent}
              onChange={(e) => setNameOfParent(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Cellphone Number</FormLabel>
            <Input
              placeholder="Cellphone Number"
              value={cellphoneNumber}
              onChange={(e) => setCellphoneNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>What services are you looking to avail?</FormLabel>
            <CheckboxGroup
              colorScheme="teal"
              value={services}
              onChange={(value) => setServices(value)}
            >
              <Stack spacing={2} direction="column">
                <Checkbox value="Occupational Therapy">Occupational Therapy</Checkbox>
                <Checkbox value="Speech Therapy">Speech Therapy</Checkbox>
                <Checkbox value="Physical Therapy">Physical Therapy</Checkbox>
                <Checkbox value="Playschool">Playschool</Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Have you consulted with a Neuro Developmental or Developmental Pediatrician?</FormLabel>
            <RadioGroup value={consulted} onChange={setConsulted}>
              <Stack direction="row">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          {consulted === 'yes' && (
            <>
              <FormControl isRequired>
                <FormLabel>Name of Pediatrician</FormLabel>
                <Input
                  placeholder="Name of Pediatrician"
                  value={nameOfPediatrician}
                  onChange={(e) => setNameOfPediatrician(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Doctor's Impression/Diagnosis</FormLabel>
                <Textarea
                  placeholder="Doctor's Impression/Diagnosis"
                  value={diagnosis}
                  onChange={(e) => setDiagnosis(e.target.value)}
                />
              </FormControl>
            </>
          )}
          {/*}
          <FormControl>
            <FormLabel>Soft Opening Early Bird Promo Package</FormLabel>
            <CheckboxGroup
              colorScheme="teal"
              value={promoPackage}
              onChange={(value) => setPromoPackage(value)}
            >
              <Stack spacing={2} direction="column">
                <Checkbox value="20% Discount for 10 sessions of Occupational Therapy">20% Discount for 10 sessions of Occupational Therapy (10 remaining)</Checkbox>
                <Checkbox value="20% Discount for 10 sessions of Physical Therapy">20% Discount for 10 sessions of Physical Therapy (10 remaining)</Checkbox>
                <Checkbox value="20% Discount for 10 sessions of Speech Therapy">20% Discount for 10 sessions of Speech Therapy (10 remaining)</Checkbox>
                <Checkbox value="20% Discount for 10 sessions of Playschool">20% Discount for 10 sessions of Playschool (10 remaining)</Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          */}
          <FormControl isRequired>
            <FormLabel>PWD Cardholder</FormLabel>
            <RadioGroup value={pwdCardholder} onChange={setPwdCardholder}>
              <Stack direction="row">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Stack>
            </RadioGroup>
            {pwdCardholder === 'yes' && (
              <FormHelperText>Note: For PWD cardholders, it will be an additional 10% discount on top of PWD discount.</FormHelperText>
            )}
          </FormControl>
          <Button type="submit" colorScheme="teal" size="lg" width="full">
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default RegistrationForm;
